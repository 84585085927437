<template>
  <d-container fluid>
    <d-row>
      <main-sidebar />
      <d-col class="main-content offset-lg-2 offset-md-3 p-0" tag="main" lg="10" md="9" sm="12">
        <main-navbar/>
        <router-view/>
      </d-col>
    </d-row>
  </d-container>
</template>

<script>
export default {
  name: 'template',
  components: {
    MainNavbar: () => import('@/components/layout/MainNavbar/MainNavbar.vue'),
    MainSidebar: () => import('@/components/layout/MainSidebar/MainSidebar.vue')
  }
}
</script>

<style>
body {
  background: #EBEBEB;
}
</style>
